@charset "utf-8";

// Theme skin
@import "basically-basic/themes/steel";

@import "basically-basic";

.jekyll-twitter-plugin {
  margin-bottom: 20px;
}
